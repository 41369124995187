/* eslint-disable no-unused-expressions */
import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import TagManager from 'react-gtm-module'
import '../assets/stylesheets/matchStyles.scss'

export const MatchLayoutQuery = graphql`
  query MatchLayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`

function MatchLayout({ children, lang}) {

  useEffect(() => {
    TagManager.initialize({gtmId: 'GTM-KNP8948'})
  },[])
  
  return (
    <StaticQuery 
      query={MatchLayoutQuery} 
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet>
            <title>{siteMetadata.title}</title>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="title" content={siteMetadata.title} />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />
            <meta name="og:title" content={siteMetadata.title} />
            <meta name="og:description" content={siteMetadata.description} />
            <meta name="twitter:title" content={siteMetadata.title} />
            <meta name="twitter:description" content={siteMetadata.description} />
            <meta name="twitter:url" content={`https://trailhead.salesforce.com/${lang}/career-path/`} />

            {
              // Google Tag Manager
            /*
            <script 
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
                  f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-KNP8948');`
              }}
            />
            */
            
              // End Google Tag Manager
            }

            <script type="text/javascript" async="" src="https://play.vidyard.com/embed/v4.js"></script>
          </Helmet>
          
          <div className="contentArea contentMatch">
            
            {children}

            <noscript 
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KNP8948" height="0" width="0"
                  style="display:none;visibility:hidden"></iframe>`
              }}
            />
          </div>
        </>
      )}
    />
  )
}

MatchLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default MatchLayout